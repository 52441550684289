// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import 'bootstrap'
import "jquery"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on('turbolinks:load', () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('.alert button.close').click(()=>{$('.alert').alert('close')}); //this is hack but works
  $('.alert').click() //this is the documented and correct way but does nothing
})

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
